import axios from "axios";
import { useRef, useState } from "react";
import Loader from "../assets/load.gif";

const Main = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [isError, setIsError] = useState(false);
  const [fname, setFname] = useState(null);
  const [lname, setLname] = useState(null);
  const [bdate, setBdate] = useState(null);
  const [sex, setSex] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [raddress, setRaddress] = useState(null);
  const [marital, setMarital] = useState(null);
  const [education, setEducation] = useState(null);
  const [occupation, setOccupation] = useState(null);
  const [fathername, setFathername] = useState(null);
  const [fathernum, setFathernum] = useState(null);
  const [fatheroccupation, setFatherOccupation] = useState(null);
  const [mothername, setMothername] = useState(null);
  const [mothernum, setMothernum] = useState(null);
  const [moheroccupation, setMotherocc] = useState(null);
  const [marritalP, setMarP] = useState(null);
  const [sibNum, setSibNum] = useState(null);
  const [care, setCare] = useState(null);
  const [carenum, setCarenum] = useState(null);
  const [ems, setEms] = useState(null);
  const [emsnum, setEmsNum] = useState(null);
  const formData = new FormData();
  const url = process.env.REACT_APP_CREATE;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    formData.append("caretaker", care);
    formData.append("caretaker_number", carenum);
    formData.append("date_of_birth", bdate);
    formData.append("education", education);
    formData.append("email", email);
    formData.append("emegency_contact", ems);
    formData.append("emegency_contact_num", emsnum);
    formData.append("father_name", fathername);
    formData.append("father_number", fathernum);
    formData.append("father_occupation", fatheroccupation);
    formData.append("first_name", fname);
    formData.append("last_name", lname);

    formData.append("marital_status", marital);
    formData.append("mother_name", mothername);
    formData.append("mother_number", mothernum);
    formData.append("mother_occupation", moheroccupation);
    formData.append("mstat_parents", marritalP);
    formData.append("num_of_siblings", parseInt(sibNum));
    formData.append("occupation", occupation);
    formData.append("phone_number", phone);
    formData.append("residential_address", raddress);
    formData.append("sex", sex);

    const data = {
      caretaker: care,
      caretaker_number: carenum,
      date_of_birth: bdate,
      education: education,
      email: email,
      emegency_contact: ems,
      emegency_contact_num: emsnum,
      father_name: fathername,
      father_number: fathernum,
      father_occupation: fatheroccupation,
      first_name: fname,
      last_name: lname,
      marital_status: marital,
      mother_name: mothername,
      mother_number: mothernum,
      mother_occupation: moheroccupation,
      mstat_parents: marritalP,
      num_of_siblings: parseInt(sibNum),
      occupation: occupation,
      phone_number: phone,
      residential_address: raddress,
      sex: sex,
    };

    await axios
      .post(url, JSON.stringify(data), {
        headers: {
          "content-type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        setIsError(false);
        setIsLoading(false);
        setMessage(res.data.message);
      })
      .catch((err) => {
        setIsError(true);
        setIsLoading(false);
        setMessage("Error Occurred while submitting data, try again");
      });
  };
  return (
    <div className="w-screen h-full grid gap-10 place-content-center bg-slate-700 px-5">
      {message !== null && isError && (
        <div className="fixed z-50 top-0 font-bold text-white bg-red-600 text-center right-0 left-0 ">
          <h1>{message}</h1>
        </div>
      )}
      {message !== null && isError !== true && (
        <div className="fixed z-50 top-0 font-bold text-white bg-green-600 text-center right-0 left-0">
          <h1>{message}</h1>
        </div>
      )}

      <p className="text-white mt-10 text-3xl text-center">
        Fill the form to complete your registration for the program
      </p>

      {/* bio */}
      <form className="bg-slate-900 w-full min-h-screen px-5 py-5 rounded-lg relative">
        {isLoading && (
          <div className="absolute bg-black w-full h-full px-5 py-5 rounded-lg top-0 right-0 bg-opacity-40 flex flex-col justify-center items-center">
            <img src={Loader} alt="" className="w-20" />
            <h2 className="text-white font-bold mt-2 text-lg">
              Submitting Data
            </h2>
          </div>
        )}
        <fieldset className="bg-slate-600 w-full h-fit px-4 py-4 grid gap-5 rounded">
          <legend className="text-white font-extrabold">Bio</legend>
          <input
            type="text"
            placeholder="First Name"
            className="form_field"
            onChange={(e) => setFname(e.target.value)}
            required
          />
          <input
            type="text"
            placeholder="Last Name"
            className="form_field"
            onChange={(e) => setLname(e.target.value)}
            required
          />
          <fieldset>
            <legend className="text-white">Date of birth</legend>
            <input
              type={"date"}
              className="form_field"
              onChange={(e) => setBdate(e.target.value)}
              required
            />
          </fieldset>
          <fieldset className="flex gap-3 bg-slate-700 px-5 py-2 rounded">
            <legend className="text-white">Sex</legend>
            <div className="text-white gap-3 flex">
              <label>Male</label>
              <input
                type={"radio"}
                name="sex"
                value="male"
                onChange={(e) => setSex(e.target.value)}
              />
            </div>
            <div className="text-white gap-3 flex">
              <label>Female</label>
              <input
                type={"radio"}
                name="sex"
                value="female"
                onChange={(e) => setSex(e.target.value)}
              />
            </div>
          </fieldset>
        </fieldset>

        {/* contact */}
        <fieldset className="bg-slate-600 w-full h-fit px-4 py-4 grid gap-5 rounded mt-5">
          <legend className="text-white font-extrabold">Contact</legend>
          <input
            type={"email"}
            placeholder="Email"
            className="form_field"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type={"tel"}
            placeholder="Phone Number"
            className="form_field"
            onChange={(e) => setPhone(e.target.value)}
          />
          <textarea
            type={"text"}
            placeholder="Residential Address"
            className="form_field resize-none pt-2"
            onChange={(e) => setRaddress(e.target.value)}
          />
        </fieldset>

        {/* marrital stat */}
        <fieldset className="bg-slate-600 w-full h-fit px-4 py-4 flex gap-5 rounded mt-5">
          <legend className="text-white font-extrabold">Marital Status</legend>
          <div className="text-white gap-3 flex">
            <label>Married</label>
            <input
              type={"radio"}
              name="marrital"
              value="married"
              onChange={(e) => setMarital(e.target.value)}
            />
          </div>
          <div className="text-white gap-3 flex">
            <label>Single</label>
            <input
              type={"radio"}
              name="marrital"
              value="single"
              onChange={(e) => setMarital(e.target.value)}
            />
          </div>
          <div className="text-white gap-3 flex">
            <label>Divorced</label>
            <input
              type={"radio"}
              name="marrital"
              value="divorced"
              onChange={(e) => setMarital(e.target.value)}
            />
          </div>
        </fieldset>

        {/* education */}
        <fieldset className="bg-slate-600 w-full h-fit px-4 py-4 flex gap-5 rounded mt-5">
          <legend className="text-white font-extrabold">Education</legend>

          <select
            name="education"
            className="w-full px-2 py-2 text-lg rounded shadow outline-none"
            onChange={(e) => setEducation(e.target.value)}
          >
            <option value="J.H.S">J.H.S</option>
            <option value="S.H.S">S.H.S</option>
            <option value="Tertiary">Tertiary</option>
          </select>
        </fieldset>

        <fieldset className="bg-slate-600 w-full h-fit px-4 py-4 grid gap-5 rounded mt-5">
          <legend className="text-white font-extrabold">Occupation</legend>
          <input
            type="text"
            placeholder="Occupation"
            className="form_field"
            onChange={(e) => setOccupation(e.target.value)}
          />
        </fieldset>

        {/* family */}
        <fieldset className="bg-slate-600 w-full h-fit px-4 py-4 grid gap-5 rounded mt-5">
          <legend className="text-white font-extrabold">Family</legend>
          <fieldset className="flex flex-col gap-3 bg-slate-700 px-5 py-2 rounded">
            <legend className="text-white">Father</legend>

            <input
              type="text"
              placeholder="Father's Name"
              className="form_field"
              onChange={(e) => setFathername(e.target.value)}
            />
            <input
              type="tel"
              placeholder="Father's Contact"
              className="form_field"
              onChange={(e) => setFathernum(e.target.value)}
            />
            <input
              type="text"
              placeholder="Father's Occupation"
              className="form_field"
              onChange={(e) => setFatherOccupation(e.target.value)}
            />
          </fieldset>
          <fieldset className="flex flex-col gap-3 bg-slate-700 px-5 py-2 rounded">
            <legend className="text-white">Mother</legend>
            <input
              type="text"
              placeholder="Mother's Name"
              className="form_field"
              onChange={(e) => setMothername(e.target.value)}
            />
            <input
              type="tel"
              placeholder="Mother's Contact"
              className="form_field"
              onChange={(e) => setMothernum(e.target.value)}
            />
            <input
              type="text"
              placeholder="Mother's Occupation"
              className="form_field"
              onChange={(e) => setMotherocc(e.target.value)}
            />
          </fieldset>

          <fieldset className="bg-slate-700 w-full h-fit px-4 py-4 flex gap-5 rounded ">
            <legend className="text-white">Parent's Marital Status</legend>
            <div className="text-white gap-3 flex">
              <label>Married</label>
              <input
                type={"radio"}
                name="marrital_parent"
                value="married"
                onChange={(e) => setMarP(e.target.value)}
              />
            </div>
            <div className="text-white gap-3 flex">
              <label>Single</label>
              <input
                type={"radio"}
                name="marrital_parent"
                value="single"
                onChange={(e) => setMarP(e.target.value)}
              />
            </div>
            <div className="text-white gap-3 flex">
              <label>Divorced</label>
              <input
                type={"radio"}
                name="marrital_parent"
                value="divorced"
                onChange={(e) => setMarP(e.target.value)}
              />
            </div>
          </fieldset>

          <fieldset className="flex flex-col gap-3 bg-slate-700 px-5 py-2 rounded">
            <legend className="text-white">Siblings</legend>
            <input
              type="number"
              placeholder="Number of Siblings"
              className="form_field"
              onChange={(e) => setSibNum(e.target.value)}
            />
          </fieldset>

          <fieldset className="flex flex-col gap-3 bg-slate-700 px-5 py-2 rounded">
            <legend className="text-white">Caretaker*</legend>
            <input
              type="text"
              placeholder="Caretaker's Name"
              className="form_field"
              onChange={(e) => setCare(e.target.value)}
            />
            <input
              type="tel"
              placeholder="Caretaker's Contact"
              className="form_field"
              onChange={(e) => setCarenum(e.target.value)}
            />
          </fieldset>
        </fieldset>

        {/* emergency */}
        <fieldset className="bg-red-600 w-full h-fit px-4 py-4 grid gap-5 rounded mt-5">
          <legend className="text-white font-extrabold">
            Emergency Contact
          </legend>
          <input
            type="text"
            placeholder="Name"
            className="form_field"
            onChange={(e) => setEms(e.target.value)}
          />
          <input
            type="tel"
            placeholder="Contact"
            className="form_field"
            onChange={(e) => setEmsNum(e.target.value)}
          />
        </fieldset>

        <button
          className=" bg-blue-400 w-full py-2 rounded shadow px-2 text-center text-xl font-bold text-white hover:bg-blue-600 transition duration-300 ease-in-out cursor-pointer mt-5"
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Submit Data
        </button>
      </form>
    </div>
  );
};

export default Main;
