import React from "react";
import { Link } from "react-router-dom";
import Main from "./Main";

const Register = () => {
  return (
    <div className="bg-sky-300 w-screen h-screen flex flex-col justify-center items-center">
      <form className="w-[450px] h-fit px-5 py-3 flex flex-col justify-center items-start rounded-lg shadow-lg gap-4 bg-white">
        <div className="grid place-self-center text-center text-gray-500 text-2xl font-bold">
          <h2 className="">NGC</h2>
          <span>Register to continue....</span>
        </div>
        <input
          type="text"
          placeholder="Enter first name..."
          className="border-b-2 border-b-sky-500 placeholder:text-gray-300 outline-none focus:outline-none w-full px-3 py-2 focus:border-b-2 focus:border-b-sky-600 text-xl"
        />
        <input
          type="text"
          placeholder="Enter last name..."
          className="border-b-2 border-b-sky-500 placeholder:text-gray-300 outline-none focus:outline-none w-full px-3 py-2 focus:border-b-2 focus:border-b-sky-600 text-xl"
        />
        <input
          type="password"
          placeholder="Enter password"
          className="border-b-2 border-b-sky-500 placeholder:text-gray-300 outline-none focus:outline-none w-full px-3 py-2 focus:border-b-2 focus:border-b-sky-600 text-xl"
          required
        />
        <input
          type="password"
          placeholder="Confirm password"
          className="border-b-2 border-b-sky-500 placeholder:text-gray-300 outline-none focus:outline-none w-full px-3 py-2 focus:border-b-2 focus:border-b-sky-600 text-xl"
          required
        />

        <Link
          to="/form"
          className="bg-green-400 w-full py-3 rounded-lg shadow px-2 text-center text-2xl font-bold text-white hover:bg-green-600 transition duration-300 ease-in-out cursor-pointer"
        >
          <button>Submit</button>
        </Link>
      </form>
    </div>
  );
};

export default Register;
